<template>
	<div id="Index">
		<nav-header></nav-header>
		<div class="out-bj">
			<keep-alive>
				<router-view id="output"></router-view>
			</keep-alive>
		</div>
		<public-footer></public-footer>
	</div>
</template>

<script>
	import NavHeader from '@/components/common/NavHeader/NavHeader.vue'
	import PublicFooter from '@/components/common/PublicFooter/PublicFooter.vue'
	export default{
		name:'Index',
		data(){
			return{
				
			}
		},
		
		methods:{
			
		},
		
		components:{
			NavHeader,
			PublicFooter
		}
	}
</script>

<style lang="scss">
 #Index{
	 background:url(../../assets/images/public/Index_bj.png) ;
		 background-attachment:fixed;
		 background-position: top;
		 background-size: 100% 100%;
		overflow-x: hidden;
	 .out-bj{
		 width: 100%;
		 
	 }
	 
	 .el-backtop{
		 background: #4854c9;
		 i{
			 color: #fff;
		 }
	 }
     #output{
          width: 14rem;
		  padding-bottom: 0.75rem;
          margin: 0 auto;
     }
 
     .Tips {
         text-align: center;
         color: #2a9fd6;
         font-size: 0.14rem;
         line-height: 0.5rem;
         cursor: pointer;
         &:hover {
             color: #2fc8ff;
        }
    }
}
</style>
