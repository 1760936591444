<template>
	<div id="" class="PublicFooter">
		<!-- <div class="PublicFooter-sum">
			<div style="display: flex; align-items: center;"><img style="width: 40px;margin-right: 10px;"
				 src="@/assets/images/PublicFooter/box.png" alt=""> {{openBoxState.openBox }}</div>
			<div style="display: flex; align-items: center;"><img style="width: 40px;margin-right: 10px;"
				 src="@/assets/images/PublicFooter/user.png" alt=""> {{openBoxState.userSum }}</div>
			<div style="display: flex; align-items: center;"><img style="width: 40px;margin-right: 10px;"
				 src="@/assets/images/PublicFooter/wifi.png" alt=""> {{openBoxState.onLine }}</div>
		</div> -->
		<div id="PublicFooter" class="PublicFooter-box">
			<div class="main">
				<div class="">
					<div class="d-flex align-items-center justify-content-between flex-wrap pb-md-0 pb-2">
						<div>
							<img class="col-md-10 col-8" src="@/assets/images/NavHeader/logo.gif" alt="">
						</div>
						<div class="XY-box d-flex">
							<p class="mr-1 pr-1 border-right cursor" @click="Toxx('/UnpackingRules')">GKDCSGO用户协议</p>
							<p class="mr-1 pr-1 border-right cursor" @click="Toxx('/UserAgreement')">用户条款 </p>
							<p class="mr-1 pr-1 border-right cursor" @click="Toxx('/Privacy')">隐私政策</p>
							<p class="mr-1" @click="Toxx('/Explosion')">可证公平性技术保障</p>
							<p class="mr-1" @click="Toxx('/changjianwenti')">常见问题</p>
						</div>
						<!-- <div class="col-2 mt-n4">
							<img style="width:100%" src="@/assets/images/PublicFooter/footer.png" alt="">
						</div> -->
					</div>
					<div class=" flex-wrap border-top pt-1 d-flex align-items-center justify-content-between ">
						<div class="left_txt col-md-6 col-12">
							@2021 gkdcsgo. all rights reserved.gkdcsgo is operated by Hong Kong Ca.daYong Network Technology Co.,
							Ltd., a company registered in Hong Kong, China.
						</div>
						<div class="d-md-block d-none">
							<img src="@/assets/images/public/footer.png" alt="">
						</div>
					</div>


				</div>

			</div>
		</div>
		<!-- <div class="Copyright text-center py-1" style="background-color: #25304d">
			浙ICP备2023013156号-1
		</div> -->
	</div>
</template>

<script>
export default {
	name: 'PublicFooter',
	data() {
		return {
			// 开箱状态
			openBoxState: {
				openBox: 0,
				userSum: 0,
				onLine: 0,
				pkSum: 0
			},
		}
	},

	created() {
			this.sumState()
	},

	methods: {
		// 作家的数字
		sumState() {
			this.openBoxState.openBox = 2150 + Math.floor(Math.random() * 100)
			this.openBoxState.userSum = 120 + Math.floor(Math.random() * 100)
			this.openBoxState.onLine = 200000 + Math.floor(Math.random() * 100)
			this.openBoxState.pkSum = 1000 + Math.floor(Math.random() * 10)
			let i = 0
			this.time = setInterval(() => {
				if(this.openBoxState.userSum < 100){
					this.openBoxState.userSum = 50 + Math.floor(Math.random() * 100)
				}
				if (Math.floor(Math.random() * 10) > 4) {
					setTimeout(() => {
						this.openBoxState.openBox += Math.floor(Math.random() * 50)
						this.openBoxState.onLine += Math.floor(Math.random() * 20)
					}, 500)
					this.openBoxState.userSum += Math.floor(Math.random() * 20)
					this.openBoxState.pkSum += Math.floor(Math.random() * 30)
				} else {
					setTimeout(() => {
						this.openBoxState.openBox -= Math.floor(Math.random() * 50)
						// this.openBoxState.onLine -= Math.floor(Math.random()*50)
					}, 500)
					this.openBoxState.userSum -= Math.floor(Math.random() * 10)

					this.openBoxState.pkSum -= Math.floor(Math.random() * 40)
				}
				i++
				if (i === 50) {
					clearInterval(this.time)
					this.sumState()
				}
			}, 1000)

		},

		//查看规则
		Toxx(val) {
			this.$router.push(val)
		},
	}
}
</script>

<style lang="scss" scoped>
.PublicFooter-sum{
	display: flex;
	justify-content: space-around;
	width: 10rem;
	margin: 0 auto;
	padding: 20px;

}
.PublicFooter{
	border-top: 1px solid #192033;
}
#PublicFooter {
	width: 100%;
	padding-bottom: 0rem;
	background: #192033;
	background-size: cover;

	.main {
		width: 14rem;
		margin: 0 auto;
		padding: .2rem 0;

		@media (max-width: 1000px) {
			width: 90%;
		}

		.left {
			width: 50%;
			box-sizing: border-box;

			p {
				font-size: 0.2rem;
				color: #fff;
			}

			.logo_Zbt {
				width: 1.2rem;
				height: 0.3rem;
				margin-top: 0.2rem;

				img {
					width: 100%;
					height: 100%;
					vertical-align: middle;
				}
			}

			.left_txt {
				font-size: 0.18rem;
				margin-top: 0.2rem;
				color: #c6c6c6;
				line-height: 1.7em;
			}

			.XY-box {
				display: flex;
				margin-top: 0.2rem;
				p {
					width: .5rem;
					height: 0.25rem;
					border-right: 1px solid #fff;
					padding-left: 0.1rem;
					box-sizing: border-box;
					margin-left: -1px;
					font-size: 0.18rem;
					line-height: 0.25rem;
					cursor: pointer;

					&:hover {
						color: #5b6bff;
					}

					&:first-child {
						padding-left: 0;
						width: 0.9rem;
					}

					&:last-child {
						border-right: none;
					}
				}
			}
		}

		.right {
			box-sizing: border-box;

			.right_logo {
				text-align: center;

				img {
					height: 1rem;
				}
			}

		}

		.ZandW {
			display: flex;
			align-items: center;

			a {
				color: #ccc;
				font-size: 12px;
			}
		}
	}

	.Copyright {
		margin-top: .2rem;
		font-size: 0.16rem;
		color: #fff;
	}
}
</style>
