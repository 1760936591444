<template>
	<div id="SwiperBox">
		<div class="SwiperBox-text" style="margin: 10px auto">
			<van-notice-bar mode="closeable"  scrollable
											style="margin: -.1rem 0;font-size:.14rem;" background="none"
											color="#fff" left-icon="volume-o">{{site_bulletin}}</van-notice-bar>
		</div>


		<div class="swiper-box">
			<van-loading class="vant-load" v-show="loadingShow" color="#000000" size="60" />
			<div class="swiper-left">
				<a :href="carouselArr[0] | RouteLink">
					<div class="swiper-item" :class="carouselArr[0].lv | LvGuoLv" dir="ltr">
						<span class="profit_ratio" v-show="carouselArr[0].profit_ratio != null">+{{carouselArr[0].profit_ratio}}</span>
						<div class="batlle" v-show="carouselArr[0].type == 3"><img src="@/assets/images/NavHeader/battle.png" alt=""></div>
						<div class="left">

							<div class="data">
								<div><img src="../../../assets/images/Swiper/diaoluo.png" alt=""></div>
								<span dir="ltr">{{carouselArr[0].name | GuoLvOne}}</span>

							</div>
							<div class="pic" :style="{'background-image': 'url('+require('@/assets/images/Swiper/bg-best-gun.png')+')'}">
								<img :src="carouselArr[0].cover" alt="">
							</div>
						</div>
					</div>
				</a>
			</div>
			<swiper v-show="loadingShow == false" class="swiper-wrapper-box" :options="swiperOption" dir="rtl">
				<swiper-slide class="swiper-slide" v-for="(item,index) in carouselArr" :key="index">
					<a :href="item | RouteLink">
						<div class="swiper-item" :class="item.lv | LvGuoLv" dir="ltr">
							<span class="profit_ratio" v-show="item.profit_ratio != null">+{{item.profit_ratio}}</span>
							<div class="batlle" v-show="item.type == 3"><img src="@/assets/images/NavHeader/battle.png" alt=""></div>
							<div class="left">
								<div class="pic" :style="{'background-image': 'url('+item.lv_bg_image+')'}">
									<img :src="item.cover" alt="">
								</div>
								<div class="data">
<!--										<p dir="ltr">{{item.box_name}}</p>-->
									<span dir="ltr">{{item.name | GuoLvOne}}</span>
								</div>
							</div>
						</div>
					</a>

					<a :href="item | RouteLink" class="user-swiper">
						<div class="swiper-item" :class="item.lv | LvGuoLv" dir="ltr">
							<span class="profit_ratio" v-show="item.profit_ratio != null">+{{item.profit_ratio}}</span>
							<div class="batlle" v-show="item.type == 3"><img src="@/assets/images/NavHeader/battle.png" alt=""></div>
							<div class="left">
								<div class="pic user_pic" :style="{'background-image': 'url('+item.lv_bg_image+')'}">
									<img style="width: 70%;height: 70%" :src="item.user.avatar" alt="">
								</div>
								<div class="data" style="text-align: center;font-size: 14px">
										<div  dir="ltr">{{item.user.name}}</div>
								</div>
							</div>

						</div>
					</a>
				</swiper-slide>
			</swiper>
		</div>
	</div>
</template>

<script>

	import {
		Swiper,
		SwiperSlide
	} from 'vue-awesome-swiper'
	import 'swiper/css/swiper.css'
	import {
		UnpackingRecord,
		Info
	} from '@/network/api.js'
	import yellow_case from '@/assets/images/PublicImg/yellow-case.png'
	import red_case from '@/assets/images/PublicImg/red-case.png'
	import violet_case from '@/assets/images/PublicImg/violet-case.png'
	import blue_case from '@/assets/images/PublicImg/blue-case.png'
	import grey_case from '@/assets/images/PublicImg/grey-case.png'
	export default {
		name: 'SwiperBox',
		data() {
			return {
				// 轮播上边
				SuiJiNum:0,
				carouselArr: [],

				loadingShow: true,

				swiperOption: {
					// 自动轮播
					autoplay: {
						delay: 2000,
						//当用户滑动图片后继续自动轮播
						disableOnInteraction: true,
					},
					simulateTouch: false,
					//开启循环模式
					loop: true,
					slidesPerView: 15,
					centeredSlides: true,
					centeredSlidesBounds: true,
				},
				site_bulletin:''
			}

		},

		filters: {
			LvGuoLv(val){
				if (val == '1') {
					return 'lv1'
				} else if (val == '2') {
					return 'lv2'
				} else if (val == '3') {
					return 'lv3'
				} else if (val == '4') {
					return 'lv4'
				}else if (val == '5') {
					return 'lv4'
				}
			},

			RouteLink(item) {
				if (item.type == '3') {
					return '/BoxFight'
				} else {
					return '/OpeningBox?id=' + item.box_id
				}
			},

			filter2(val) {
				if (val == '黄') {
					return yellow_case
				} else if (val == '红') {
					return red_case
				} else if (val == '紫') {
					return violet_case
				} else if (val == '蓝') {
					return blue_case
				} else {
					return grey_case
				}
			},
			GuoLvOne(val) {
				return val.length > 12 ? val.substr(0, 10) + '...' : val
			}
		},

		created() {
			this.GetUnpackingRecord()
			this.TenSeconds()
			this.GetInfo()
		},


		methods: {
			//开奖记录
			GetUnpackingRecord() {
				UnpackingRecord().then((res) => {
					// console.log(res.data.data.historylist)
					this.carouselArr = res.data.data.historylist

				})
			},
			TenSeconds() {
				setInterval(() => {
					this.GetUnpackingRecord()
				}, 10000)
			},

			GetInfo(){
				Info().then((res) => {
					// console.log(res.data.data)
					this.site_bulletin = res.data.data.site_bulletin
				})
			}

		},



		watch: {
			carouselArr: {
				deep: true,
				handler: function(newV, oldV) {
					this.loadingShow = false
					// console.log('watch中：', newV)
					this.carouselArr = newV
				}
			}
		},


		components: {
			Swiper,
			SwiperSlide
		}
	}
</script>

<style lang="scss">

	#SwiperBox {
		.swiper-wrapper-box{
			height: 1.5rem;
		}
		.user_pic{
			border-radius: 50%;
			overflow: hidden;
			img{
				border-radius: 50%
			}
		}
		width: 100%;

		position: relative;
		z-index: 10;
		margin-top: .72rem;
		/*padding-top: .2rem;*/
		overflow: hidden;
		.swiper-box {
			width: 100%;
			position: relative;
			height: 1.5rem;
			display: flex;
			.vant-load {
				text-align: center;
				line-height: 1.4rem;
			}

			.swiper-left{
				width: 3rem;
				background-size: 100% 100%;
				background-image: url("../../../assets/images/Swiper/bg-recent-best.png");
				position: relative;
				&::before{
					content: '';
					width: 10px;
					height: 100%;
					background: #232330;
					right: 0;
					position: absolute;
				}
				.swiper-item {
					width: 3rem;
					height: 1.5rem;
					cursor: pointer;
					position: relative;
					display: flex;
					justify-content: center;
					color: #fff;
					.batlle{
						width: 20px;
						position: absolute;
						right: 5px;
						top: 5px;
						img{
							width: 100%;
						}
					}

					.profit_ratio{
						width: 50px;
						position: absolute;
						right: 70px;
						top: 5px;
						font-size: 8px;
					}
					.left {
						height: 100%;
						display: flex;
						align-items: center;
						padding: 0 1rem;
						.pic {
							width: 1.5rem;
							height: 1.1rem;
							background-repeat: no-repeat;
							background-size: 100%;
							background-position: center;
							display: flex;
							justify-content: center;
							align-items: center;
							img {
								max-width: 100%;
								max-height: 100%;
							}
						}

						.data {
							/*width: 1rem;*/
							// background: red;
							/*height: .8rem;*/
							p {
								width: .8rem;
								font-size: .20rem;
								margin-top: .18rem;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								color: #d42d4f;
							}


							span {
								display: block;
								margin-top: .02rem;
								width: 100%;
								font-size: .14rem;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}
						}
					}
				}
			}
			.swiper-slide {
				width: 1.5rem !important;
				/*padding: 0 0.1rem;*/
				box-sizing: border-box;
				transition:all .8s;
				margin-left: 0.01rem;
				background-color: #18192c;

				&:hover{
					& .user-swiper{
						border: 0!important;
						opacity:.9;
						transform: rotateY(0deg);
					}
				}
				.swiper-item {
					width: 100%;
					height: 1.5rem;
					cursor: pointer;
					position: relative;
					// border-radius: 0.04rem;
					// padding: .05rem;
					box-sizing: border-box;
					display: flex;
					justify-content: center;
					color: #fff;
					&.lv1{
						background: url(../../../assets/images/NavHeader/LV1.png) no-repeat center;
						background-size: 100% 100%;
					}
					&.lv2{
						background: url(../../../assets/images/NavHeader/LV2.png) no-repeat center;
						background-size: 100% 100%;
					}
					&.lv3{
						background: url(../../../assets/images/NavHeader/LV3.png) no-repeat center;
						background-size: 100% 100%;
					}
					&.lv4{
						background: url(../../../assets/images/NavHeader/LV4.png) no-repeat center;
						background-size: 100% 100%;
					}

					.batlle{
						width: 20px;
						position: absolute;
						right: 5px;
						top: 5px;
						img{
							width: 100%;
						}
					}

					.profit_ratio{
						width: 50px;
						position: absolute;
						right: 70px;
						top: 5px;
						font-size: 8px;
					}

					.left {
						height: 100%;
						/*display: flex;*/
						/*align-items: center;*/
						.pic {
							width: 1.1rem;
							height: 1.1rem;
							background-repeat: no-repeat;
							background-size: 70%;
							background-position: center;
							display: flex;
							justify-content: center;
							align-items: center;

							img {
								max-width: 100%;
								max-height: 100%;
							}
						}

						.data {
							/*width: 1rem;*/
							// background: red;
							/*height: .8rem;*/
							p {
								width: .8rem;
								font-size: .20rem;
								margin-top: .18rem;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								color: #d42d4f;
							}


							span {
								display: block;
								margin-top: .02rem;
								width: 100%;
								font-size: .14rem;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}
						}
					}

					.right {
						width: 1.1rem;
						text-align: center;
						padding-top: .1rem;
						padding-right: .1rem;
						box-sizing: border-box;
						img {
							width: .3rem;
							height: .3rem;
							border-radius: 50%;
							box-sizing: border-box;
						}
						p{
							font-size: .12rem;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
					}
				}
			}
		}
	}


	.user-swiper{
		position: absolute;
		top: 0;
		left: 12%;
		opacity:0;
		transform: rotateY(180deg);
		transform-origin: 50% 0;
		transition: all .5s;
	}
</style>
